<template>
  <div class="home-container">
    <!-- Willkommen-Text -->
    <h1 class="welcome-title">Willkommen bei BelkaMC</h1>
    
    <!-- Bildkarussell -->
    <div class="carousel">
      <button @click="prevImage" class="carousel-button left">&#10094;</button>
      <img :src="images[currentImage]" alt="BelkaMC Image" class="carousel-image"/>
      <button @click="nextImage" class="carousel-button right">&#10095;</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // Pfade zu den Bildern
      images: [
        require('@/assets/Homepage1.png'),
        require('@/assets/Homepage2.png'),
        require('@/assets/Homepage3.png'),
        require('@/assets/Homepage4.png'),
        require('@/assets/Homepage5.png')
      ],
      currentImage: 0
    };
  },
  methods: {
    // Funktion, um zum vorherigen Bild zu wechseln
    prevImage() {
      this.currentImage = (this.currentImage + this.images.length - 1) % this.images.length;
    },
    // Funktion, um zum nächsten Bild zu wechseln
    nextImage() {
      this.currentImage = (this.currentImage + 1) % this.images.length;
    }
  }
};
</script>

<style scoped>
.home-container {
  text-align: center;
  padding: 40px 20px;
}

/* Willkommen-Text */
.welcome-title {
  font-family: 'Roboto', sans-serif;
  font-size: 3em;
  color: var(--title-color-light);
  text-shadow: -2px -2px 0 var(--title-shadow-light),
               2px -2px 0 var(--title-shadow-light),
              -2px  2px 0 var(--title-shadow-light),
               2px  2px 0 var(--title-shadow-light);
  margin-bottom: 50px;
}

.dark-mode .welcome-title {
  color: var(--title-color-dark);
  text-shadow: -2px -2px 0 var(--title-shadow-dark),
               2px -2px 0 var(--title-shadow-dark),
              -2px  2px 0 var(--title-shadow-dark),
               2px  2px 0 var(--title-shadow-dark);
}

/* Bildkarussell */
.carousel {
  position: relative;
  width: 90%;  /* Vergrößert die Breite des Karussells */
  max-width: 1200px; /* Maximale Breite für größere Bildschirme */
  margin: 0 auto;
}

.carousel-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Linke und rechte Buttons */
.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 15px;
  font-size: 2.5em; /* Vergrößert die Buttons */
  cursor: pointer;
  border-radius: 50%;
  z-index: 10;
}

.carousel-button.left {
  left: -60px; /* Abstand für größere Buttons */
}

.carousel-button.right {
  right: -60px; /* Abstand für größere Buttons */
}

.carousel-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
</style>

<template>
  <div class="youtube-container">
    <h1 class="youtube-title">YouTube Kanäle</h1>
    <div class="youtube-channels">
      <!-- Commander Greetv Kanal -->
      <div class="channel">
        <a :href="commandergreetvUrl" target="_blank" rel="noopener noreferrer">
          <img :src="commandergreetvImage" alt="Commander Greetv Kanal" class="channel-image" />
        </a>
        <div class="channel-details">
          <h2 class="channel-title">Commandergreetv</h2>
          <p class="channel-description">Auf diesem Kanal finden sie Hymnen zu den Ländern BelkaMCs.</p>
        </div>
      </div>
      <!-- Zeyyco Kanal -->
      <div class="channel">
        <a :href="zeyycoUrl" target="_blank" rel="noopener noreferrer">
          <img :src="zeyycoImage" alt="Zeyyco Kanal" class="channel-image" />
        </a>
        <div class="channel-details">
          <h2 class="channel-title">zeyyco</h2>
          <p class="channel-description">Hier werden Lore Videos zu BelkaMC kommen.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'YouTube',
  data() {
    return {
      // URL der YouTube-Kanäle
      commandergreetvUrl: 'https://www.youtube.com/@commandergreetv753',
      zeyycoUrl: 'https://www.youtube.com/@zeyyco',
      
      // URLs der lokal gespeicherten Kanalbilder
      commandergreetvImage: require('@/assets/cgtvytpb.png'), // Ersetze durch Pfad zur Bilddatei
      zeyycoImage: require('@/assets/zeyycoytpb.png') // Ersetze durch Pfad zur Bilddatei
    };
  }
};
</script>

<style scoped>
.youtube-container {
  padding: 20px;
  text-align: left; /* Linksbündige Ausrichtung */
}

/* Stellt sicher, dass die Überschrift auf der gleichen Höhe wie "Impressum" ist */
.youtube-title {
  font-family: 'Roboto', sans-serif;
  font-size: 2em;
  color: var(--title-color-light);
  text-shadow: -2px -2px 0 var(--title-shadow-light),
               2px -2px 0 var(--title-shadow-light),
              -2px  2px 0 var(--title-shadow-light),
               2px  2px 0 var(--title-shadow-light);
  margin: 0; /* Entfernt den Standard-Margin */
  padding-bottom: 20px; /* Abstand nach unten */
}

.dark-mode .youtube-title {
  color: var(--title-color-dark);
  text-shadow: -2px -2px 0 var(--title-shadow-dark),
               2px -2px 0 var(--title-shadow-dark),
              -2px  2px 0 var(--title-shadow-dark),
               2px  2px 0 var(--title-shadow-dark);
}

.youtube-channels {
  display: flex;
  flex-direction: column;
}

.channel {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.channel a {
  display: block;
  width: 150px; /* Größe des Kanalbilds */
  height: 150px;
}

.channel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.channel-details {
  margin-left: 20px;
}

.channel-title {
  font-size: 1.5em;
  font-weight: bold;
  color: var(--title-color-light);
}

.dark-mode .channel-title {
  color: var(--title-color-dark);
}

.channel-description,
.video-date {
  font-size: 1em;
  color: var(--text-color-light);
}

.dark-mode .channel-description,
.dark-mode .video-date {
  color: var(--text-color-dark);
}
</style>

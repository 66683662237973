import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue'; // Importiere die HomePage
import TeamPage from '../views/TeamPage.vue';
import FandomPage from '../views/FandomPage.vue';
import WasIstBelkaMCPage from '@/views/WasIstBelkaMCPage.vue';
import YouTubePage from '@/views/YouTubePage.vue';
import ImpressumPage from '../views/ImpressumPage.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage // Verwende HomePage als Komponente für die Startseite
  },
  {
    path: '/team',
    name: 'Team',
    component: TeamPage
  },
  {
    path: '/fandom',
    name: 'Fandom',
    component: FandomPage
  },
  {
    path: '/was-ist-belkamc',
    name: 'Was ist BelkaMC',
    component: WasIstBelkaMCPage
  },
  {
    path: '/youtube',
    name: 'YouTube',
    component: YouTubePage
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: ImpressumPage
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
